/**
 *
 * @param id
 * @returns {*}
 */
export function resetScore(id) {
    return axios({
        url: '/api/partners/' + id + '/reset-score',
        method: 'post',
    });
}

/**
 *
 * @param userId
 * @param amount
 * @returns {*}
 */
export function accountTopUpClient(userId, amount) {
    return axios({
        url: '/api/partners/' + userId + '/account-top-up',
        method: 'post',
        data: {
            amount: amount
        }
    });
}

/**
 *
 * @returns {*}
 * @param data
 */
export function save(data) {
    return axios({
        url: '/api/partners',
        method: 'post',
        data:data
    });
}

/**
 *
 * @param userId
 * @param data
 * @returns {*}
 */
export function update(userId, data) {
    return axios({
        url: '/api/partners/' + userId,
        method: 'put',
        data: data
    });
}

/**
 *
 * @param userId
 * @param limit
 * @returns {*}
 */
export function changeLimit(userId, limit) {
    return axios({
        url: '/api/partners/' + userId + '/change-limit',
        method: 'post',
        data: {
            limit: limit
        }
    });
}

export function changePenaltySetting(userId, status, commission) {
    return axios({
        url: '/api/partners/' + userId + '/penalty-setting',
        method: 'post',
        data: {
            isActive: status,
            commission: commission
        }
    });
}
